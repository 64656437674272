import React, { useEffect, useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import tw, { css } from 'twin.macro';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { contentStyles, Article, Container, Inner, Title } from '../components';
import ContentLabel from '../components/ContentLabel';
import ProgressBar from '../components/ProgressBar';
import ShareButton from '../components/ShareButton';
import RelatedRibbon from '../components/RelatedRibbon';
import FAQAccordion from '../components/FAQAccordion/index';
import Breadcrumbs from '../components/Breadcrumbs';
import DateAndTimeToRead from '../components/DateAndTimeToRead';

const ArticlePage = ({
  data: {
    datoCmsArticle: {
      title,
      date,
      slug,
      dateTime,
      contentNode: {
        childMarkdownRemark: { html, fields },
      },
      blocks,
      shortIntroNode,
      featuredMedia,
      seo,
    },
    relatedPosts,
  },
}) => {
  const posts = relatedPosts.edges.map((p) => p.node);
  return (
    <Layout articleType="Article">
      <Article>
        <SEO title={title} articleType="Article" {...seo} slug={slug} dateTime={dateTime} />
        <ProgressBar>
          <Container>
            <Inner mt>
              <ContentLabel label="Article" />
              <Breadcrumbs post={{ title, slug, articleType: 'Article' }} />
              <Title>{title}</Title>
              <DateAndTimeToRead
                date={date}
                dateTime={dateTime}
                readingTimeText={fields.readingTime.text}
              />
              {shortIntroNode && (
                <div
                  css={[contentStyles, tw`text-lg font-medium mb-0 mt-0 sm:mt-0`]}
                  dangerouslySetInnerHTML={{ __html: shortIntroNode.childMarkdownRemark.html }}
                />
              )}
            </Inner>
            <GatsbyImage image={featuredMedia.gatsbyImageData} alt={title} tw="text-transparent" />
            <div css={contentStyles} dangerouslySetInnerHTML={{ __html: html }} />
            <Inner>
              {blocks?.map((block, i) => {
                switch (block.model.apiKey) {
                  case 'accordion':
                    return <FAQAccordion topics={block.topics} key={i} />;
                }
                return null;
              })}
            </Inner>
          </Container>
        </ProgressBar>
      </Article>
      <RelatedRibbon articleType="Article" sameAsArticle={title} posts={posts} />
    </Layout>
  );
};

export default ArticlePage;

export const articleQuery = graphql`
  query ($id: String) {
    datoCmsArticle(id: { eq: $id }) {
      title
      date(formatString: "DD.MM.YYYY")
      dateTime: date(formatString: "YYYY-MM-DD")
      slug
      excerpt
      shortIntroNode {
        childMarkdownRemark {
          html
        }
      }
      contentNode {
        childMarkdownRemark {
          html
          fields {
            readingTime {
              text
            }
          }
        }
      }
      blocks {
        model {
          apiKey
        }
        topics {
          heading
          bodyText {
            value
          }
        }
      }
      featuredMedia {
        gatsbyImageData(layout: CONSTRAINED, width: 1280)
      }
      seo {
        title
        description
        image {
          url
        }
      }
    }
    relatedPosts: allDatoCmsArticle(
      limit: 10
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      edges {
        node {
          title
          slug
          ribbonCaption
          ribbonImage: featuredMedia {
            gatsbyImageData(layout: CONSTRAINED, width: 384)
          }
          contentNode {
            childMarkdownRemark {
              excerpt
              html
            }
          }
          model {
            name
          }
          date(formatString: "DD.MM.YYYY")
          dateTime: date(formatString: "YYYY.MM.DD")
        }
      }
    }
  }
`;
