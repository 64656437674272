import React from 'react';
import tw from 'twin.macro';
import ReadingTime from './ReadingTime';

const DateAndTimeToRead = ({ date, dateTime, readingTimeText }) => (
  <div tw="flex mb-4 opacity-50 text-sm">
    <time dateTime={dateTime} tw="mr-8">
      {date}
    </time>
    <span>{readingTimeText}</span>
  </div>
);

export default DateAndTimeToRead;
