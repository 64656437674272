import React from 'react';
import tw, { css } from 'twin.macro';
import { motion, AnimatePresence } from 'framer-motion';
import { AccordionArrow } from '../Icons';

const titleStyles = tw`
  py-3 text-blue-dark transition-colors duration-300 ease-linear text-lg font-semibold
`;
const arrowStyles = tw`
  text-red transition-transform duration-300 ease-linear transform
`;

const variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  collapsed: {
    opacity: 0,
    height: 0,
  },
};

const contentStyles = css`
  p {
    ${tw`mb-2`}
    &:last-of-type {
      ${tw`mb-0`}
    }
  }
  a {
    text-decoration: underline;
  }
  li {
    margin-left: 1rem;
    list-style: square;
  }
`;

export const AccordionHead = ({ setExpanded, expanded, label }) => (
  <button
    onClick={setExpanded}
    tw="w-full flex justify-between items-center cursor-pointer text-left"
  >
    <h3 css={[titleStyles, expanded && tw`text-green`]}>{label}</h3>
    <AccordionArrow
      css={[
        arrowStyles,
        expanded && tw`rotate-180`,
        css`
          path {
            stroke-width: 2;
          }
        `,
      ]}
    />
  </button>
);

const AccordionItem = ({ children, expanded }) => (
  <>
    <AnimatePresence>
      {expanded && (
        <motion.section
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={variants}
          transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          tw="overflow-hidden w-full"
        >
          <div css={contentStyles} tw="py-5 flex flex-col h-full">
            {children}
          </div>
        </motion.section>
      )}
    </AnimatePresence>
    <hr tw="text-blue-dark w-full my-3 opacity-20" />
  </>
);

export default AccordionItem;
