import React, { useState } from 'react';
import tw from 'twin.macro';
import { StructuredText } from 'react-datocms';
import AccordionItem, { AccordionHead } from './AccordionItem';

const Topics = tw.div`text-left w-full`;
const Heading = tw.h2`text-red font-semibold m-0 leading-tight text-xl sm:text-4xl`;

const Accordion = ({ heading, bodyText, topics, illustration }) => {
  const [activeTab, setActiveTab] = useState();
  const [expanded, setExpanded] = useState(10);

  const handleExpand = (q, idx) => {
    const closeItself = expanded === idx && q === activeTab;
    setActiveTab(q);
    setExpanded(closeItself ? null : idx);
  };

  return (
    <div tw="text-blue-dark">
      <Heading>{heading}</Heading>
      {bodyText && <p>{bodyText}</p>}
      <div tw="flex items-center flex-col-reverse md:(flex-row gap-6)">
        <Topics>
          {topics?.map(({ heading, bodyText }, i) => {
            const isExpanded = expanded === i;
            return (
              <React.Fragment key={i}>
                <AccordionHead
                  setExpanded={() => handleExpand(heading, i)}
                  expanded={isExpanded}
                  label={heading}
                />
                <AccordionItem i={i} expanded={isExpanded}>
                  <StructuredText data={bodyText} />
                </AccordionItem>
              </React.Fragment>
            );
          })}
        </Topics>
      </div>
    </div>
  );
};

export default Accordion;
