import React, { useEffect, useState, useRef } from 'react';
import tw, { css } from 'twin.macro';

const containerStyles = css`
  // Light Green
  background-color: #dff3f1;
  // Light Red
  background-color: #fccfde;
  transform: translateY(-100%);
  ${tw`fixed top-0 left-0 h-2 w-full z-50 transition-transform`}
`;

const showBarStyles = tw`translate-y-0`;

const progressBarStyles = css`
  will-change: transform;
  ${tw`h-full bg-red origin-left`}
`;

const ProgressBar = ({ children }) => {
  const ref = useRef();
  const [showBar, setShowBar] = useState(false);
  const [transform, setTransform] = useState(`scaleX(0%)`);

  const onScroll = () => {
    if (typeof window !== 'undefined' && ref?.current) {
      const winH = window.innerHeight;
      const elH = ref.current.getBoundingClientRect().height;
      const pxComplete = elH - winH;

      const scrollPos = document.documentElement.scrollTop;
      const percentage = (scrollPos / pxComplete) * 100;
      const total = percentage < 100 ? percentage : 100;

      const zero = total <= 0;
      const under100 = total > 0 && total <= 100;

      const transformValue = zero ? `scaleX(0%)` : under100 ? `scaleX(${total}%)` : `scaleX(100%)`;
      const showValue = !zero;

      setShowBar(showValue);
      setTransform(transformValue);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [ref]);

  return (
    <>
      <div css={[containerStyles, showBar && showBarStyles]}>
        <div css={progressBarStyles} style={{ transform }}></div>
      </div>
      <div ref={ref}>{children}</div>
    </>
  );
};

export default ProgressBar;
